import { UserToken, GatewayService, UserEmail } from "@phantom-chen/cloud77";
import axios from "axios";
import { getAuthorization, getKey, setHome, setKey, setRefreshToken, setToken } from "./storage";

export function getGatewayApp(): Promise<GatewayService> {
    return axios<GatewayService>('/api/gateway').then(res => {
        setKey(res.data.apikey);
        setHome(res.data.home);
        return Promise.resolve(res.data);
    }).catch(e => {
        console.log(e.message);
        return Promise.reject(e);
    });
}

export function getApp(name: string): void {
    axios.get('/super-app/service', {
        headers: {
            'x-api-key': getKey(),
            'Authorization': getAuthorization()
        }
    }).then(res => {
        console.log(res.data);
    })
}

export function getHealth(name: string): void {
    axios.get('/user-app/health', {
        headers: {
            'x-api-key': getKey(),
            'Authorization': getAuthorization(),
        },
        responseType: 'text'
    }).then(res => {
        console.log(res.data);
    })
}

export function verifyUser(email: string): void {
    axios.get('/identity-app/users', {
        headers: {
            'x-api-key': getKey()
        },
        params: { email }
    }).then(res => {
        console.log(res.data);
    });    
}

export function getToken(user: { email: string, password: string }): Promise<UserToken> {
    return axios.get<UserToken>('/identity-app/users/tokens', {
        headers: {
            'x-api-key': getKey()
        },
        params: {
            email: user.email,
            password: user.password
        }
    }).then(res => {
        setToken(res.data.value);
        setRefreshToken(res.data.refreshToken);
        return Promise.resolve(res.data);
    }).catch(err => {
        console.log(err);
        return Promise.reject(err);
    })
}

export function verifyToken(): Promise<UserEmail> {
    return axios.get<UserEmail>('/user-app/users/roles', {
        headers: {
            'x-api-key': getKey(),
            'Authorization': getAuthorization(),
        }
    }).then(res => {
        return Promise.resolve(res.data)
    }).catch(err => {
        return Promise.reject(err);
    })
}