export function debugMode(): boolean {
    return sessionStorage.getItem('debug') ? true : false;
}

export function mockupMode(): boolean {
    return sessionStorage.getItem('mockup') ? true : false;
}

export function setHome(home: string) {
    if (home) {
        if (!localStorage.getItem('home'))
            localStorage.setItem('home', home);
    } else {
        localStorage.removeItem('home');
    }
}

export function getHome(): string {
    return localStorage.getItem('home') || '';
}

export function getKey(): string {
    return localStorage.getItem('apikey') || '';
}

export function setKey(key: string): void {
    if (key) {
        if (!localStorage.getItem('apikey'))
            localStorage.setItem('apikey', key);
    } else {
        localStorage.removeItem('apikey');
    }
}

export function getToken(): string {
    return sessionStorage.getItem('user-access-token') || '';
}

export function setToken(token: string): void {
    if (token) {
        sessionStorage.setItem('user-access-token', token);
    } else {
        sessionStorage.removeItem('user-access-token');
    }
}

export function getRefreshToken(): string {
    return sessionStorage.getItem('user-refresh-token') || '';
}

export function setRefreshToken(token: string): void {
    if (token) {
        sessionStorage.setItem('user-refresh-token', token);
    } else {
        sessionStorage.removeItem('user-refresh-token');
    }
}

export function getUser(): string {
    return sessionStorage.getItem('user-email') || '';
}

export function setUser(user: string): void {
    if (user) {
        sessionStorage.setItem('user-email', user);
    } else {
        sessionStorage.removeItem('user-email');
    }
}

export function getAuthorization(): string {
    return `Bearer ${getToken()}`;
}

export function clearStorage(isLocal: boolean = false) {
    if (isLocal) {
        localStorage.clear();
    } else {
        sessionStorage.clear();
    }
}
